<template>
  <div class="app">
<!--    <Header :index="active" v-if="location == ''"></Header>-->
    <lanzhouHeader :index="active"/>
    <router-view v-wechat-title="$route.meta.title" @goRouter="toTab"/>
    <lanzhouFooter />
<!--    <Footer v-if="location == ''"></Footer>-->
  </div>
</template>
<script>
import Header from "@/components/public/newHeader";
import Footer from "@/components/public/Footer";
import lanzhouFooter from "@/components/shanxi-cdpe/footer";
import lanzhouHeader from "@/components/lanzhou-cdpe/header";

import {
  mapState
} from 'vuex';
const meta = {

}
export default {
  name: "index",
  inject: ["reload"],
  components: {
    Header,
    lanzhouHeader,
    Footer,
    lanzhouFooter,
  },
  data() {
    return {
      active: "",
      location: "",
    };
  },
  computed: {
    ...mapState(["allCity"])
  },
  methods: {
    toTab(val) {
      this.active = val;
    },
    goTop() {
      window.scrollTo(0, 0);
    },
    async getUrl() {
      // debugger;
      let res = await this.$api.memberOperLogApi({
        url: encodeURIComponent(this.$route.fullPath),
        oper: 0
      });
    },
  },
  created() {
    this.toTab();
    let login =
        this.$store.state.userType || JSON.parse(localStorage.getItem("userType")) || '';
    this.$nextTick(() => {
      //判断之前是否进入过子站
      this.location = this.allCity || localStorage.getItem("locationSite") || '';
    })
  },
  mounted() {
    this.goTop();
    this.getUrl();

  }
};
</script>

<style scoped lang="less">
/deep/ .el-notification.left {
  top: 50% !important;
}

.app {
  background: rgba(242, 242, 242);
  // background: #f7f7f7;
}
</style>
